const formatDate = stringDateTime => {
  const date = new Date(stringDateTime);
  return `${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}/${date.getDate() < 10 ? '0' : ''}${date.getDate()}/${date.getFullYear()}`;
}

const formatTextDate = date => {
  let result = '';
  result += date.toLocaleString('default', { month: 'short' }) + ' ';
  result += date.getDate() + ', ';
  result += date.getFullYear();
  return result;
}

const formatDateTime = stringDateTime => {
  const date = new Date(stringDateTime);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${formatDate(stringDateTime)} ${hours}:${minutes} ${ampm}`;
}

const capitalize = text => text.charAt(0).toUpperCase() + text.slice(1)

const prettyString = (string = '', spliter = '', chunkSize = 3) => {
  string = `${string}`;
  if (string.length <= chunkSize) {
    return string;
  }
  let result = '';
  for (let i = string.length; i >= chunkSize * -1 + 1; i -= chunkSize) {
    const chunk = string.substring(i <= 0 ? 0 : i, i === string.length ? i : i + chunkSize);
    result = `${spliter}${chunk}${result}`;
  }
  return result.substring(1, result.length - 1);
}

const util = {
  formatDate,
  formatTextDate,
  formatDateTime,
  capitalize,
  prettyString
};

export default util;
