import { useState, useCallback } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useNavigate } from '@shopify/app-bridge-react';
import { Page, Icon, Popover, ActionList } from '@shopify/polaris';
import { SettingsMajor } from '@shopify/polaris-icons';
import Redirect from 'components/Redirect';
import Footer from 'components/Footer';
import DisconnectModal from 'components/DisconnectModal';
import serviceShopify from 'services/shopify';
import serviceUser from 'services/user';
import utilConstants from 'utils/constants';
import s from './index.module.scss';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEmailVerefied, setIsEmailVerefied] = useState(serviceUser.getIsEmailVerefied());
  const [onboardStep, setOnboardStep] = useState(serviceUser.getOnboardStep());
  const [isOnboardedShopify, setIsOnboardedShopify] = useState(serviceUser.getIsOnboardedShopify());

  const navigate = useNavigate();
  const location = useLocation();

  const toggleIsOpenPopover = useCallback(
    () => setIsOpenPopover(isOpenPopover => !isOpenPopover),
    []
  )

  const handleOpenModal = () => setIsOpenModal(true)

  const handleClickLink = link => {
    document.body.click();
    navigate(link);
  }

  if (!serviceShopify.getIsConnected()) {
    return (
      <Route
        render={() => <Redirect to='/login' />}
        {...rest}
      />
    );
  }

  if (!isEmailVerefied && location.pathname !== '/account-settings/verify-email') {
    return (
      <Route
        render={() => <Redirect to='/account-settings/verify-email' />}
        {...rest}
      />
    );
  }

  const handleDoneEmailVerification = () => {
    serviceUser.setIsEmailVerefied(true);
    setIsEmailVerefied(true);
  }

  if (!isEmailVerefied) {
    return (
      <Route
        render={() => (
          <div className={s['private-route']}>
            <div>
              <Page
                primaryAction={(
                  <Popover
                    active={isOpenPopover}
                    activator={(
                      <div
                        className={s['private-route__icon-wrapper']}
                        onClick={toggleIsOpenPopover}
                      >
                        <Icon source={SettingsMajor} color='base' />
                      </div>
                    )}
                    onClose={toggleIsOpenPopover}
                  >
                    <ActionList
                      items={
                        [
                          {
                            content: 'Disconnect',
                            onAction: handleOpenModal
                          }
                        ]
                      }
                    />
                  </Popover>
                )}
              >
              </Page>
              <Component onDone={handleDoneEmailVerification} />
              <DisconnectModal
                isOpen={isOpenModal}
                onClose={() => setIsOpenModal(false)}
              />
            </div>
          </div>
        )}
        {...rest}
      />
    );
  }

  if (onboardStep <= utilConstants.lastOnboardStep && location.pathname !== '/account-settings/onboarding') {
    return (
      <Route
        render={() => <Redirect to='/account-settings/onboarding' />}
        {...rest}
      />
    );
  }

  const handleDoneOnboarding = () => {
    serviceUser.setOnboardStep(utilConstants.lastOnboardStep + 1);
    setOnboardStep(utilConstants.lastOnboardStep + 1);
  }

  if (onboardStep <= utilConstants.lastOnboardStep) {
    return (
      <Route
        render={() => <Component onDone={handleDoneOnboarding} />}
        {...rest}
      />
    );
  }

  if (!isOnboardedShopify && location.pathname !== '/account-settings/startup-guide') {
    return (
      <Route
        render={() => <Redirect to='/account-settings/startup-guide' />}
        {...rest}
      />
    );
  }

  const handleDoneOnboardingShopify = () => {
    serviceUser.setIsOnboardedShopify(true);
    setIsOnboardedShopify(true);
  }

  if (!isOnboardedShopify) {
    return (
      <Route
        render={() => <Component onDone={handleDoneOnboardingShopify} />}
        {...rest}
      />
    );
  }

  return (
    <Route
      render={() => (
        <div className={s['private-route']}>
          <div>
            <Page
              primaryAction={(
                <Popover
                  active={isOpenPopover}
                  activator={(
                    <div
                      className={s['private-route__icon-wrapper']}
                      onClick={toggleIsOpenPopover}
                    >
                      <Icon source={SettingsMajor} color='base' />
                    </div>
                  )}
                  onClose={toggleIsOpenPopover}
                >
                  <ActionList
                    items={
                      [
                        {
                          content: 'Account settings',
                          onAction: () => handleClickLink('/account-settings')
                        },
                        {
                          content: 'Startup guide',
                          onAction: () => handleClickLink('/account-settings/startup-guide')
                        },
                        {
                          content: 'Disconnect',
                          onAction: handleOpenModal
                        }
                      ]
                    }
                  />
                </Popover>
              )}
            >
            </Page>
            <Component />
            <DisconnectModal
              isOpen={isOpenModal}
              onClose={() => setIsOpenModal(false)}
            />
          </div>
          <Footer />
        </div>
      )}
      {...rest}
    />
  );
}

export default PrivateRoute;
