import utilUrl from 'utils/url';

const shopifyHostLocalStorageKey = `shopifyHost`;
const getShopifyHost = () => {
  try {
    return localStorage.getItem(shopifyHostLocalStorageKey);
  } catch {
    return null;
  }
}
const saveShopifyHost = shopifyHost => {
  try {
    localStorage.setItem(shopifyHostLocalStorageKey, shopifyHost);
  } catch {

  }
}

const shopifyAppApiKey = process.env.REACT_APP_SHOPIFY_APP_API_KEY;
const shopifyAppScope = process.env.REACT_APP_SHOPIFY_APP_SCOPE;
const shopifyAppRedirectUri = process.env.REACT_APP_SHOPIFY_REDIRECT_URI;
const shopifyAppId = process.env.REACT_APP_SHOPIFY_APP_ID;
const shopifyHost = utilUrl.getQueryParam('host') || getShopifyHost();
saveShopifyHost(shopifyHost);
const shopifyAppBridgeConfg = {
  apiKey: shopifyAppApiKey,
  host: shopifyHost
};

const lastOnboardStep = 2;

const completedActivationsFilterOptionsValue = 'completed';
const activationsFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Live', value: 'live' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Completed', value: completedActivationsFilterOptionsValue }
];
const indexOfCompletedActivationsFilterOptions = activationsFilterOptions.findIndex(
  activationFilterOptions => activationFilterOptions.value === completedActivationsFilterOptionsValue
);
const activationTimes = new Array(24).fill(0).map((_, index) => ({
  label: `${index < 10 ? '0' + index : index}:00`,
  value: `${index < 10 ? '0' + index : index}:00`
}));

const util = {
  shopifyAppApiKey,
  shopifyAppScope,
  shopifyAppRedirectUri,
  shopifyAppId,
  shopifyAppBridgeConfg,
  lastOnboardStep,
  activationsFilterOptions,
  indexOfCompletedActivationsFilterOptions,
  activationTimes
};

export default util;
