import { Spinner } from '@shopify/polaris';
import s from './index.module.scss';

const Loader = () => (
  <div className={s['loader']}>
    <div>
      <Spinner />
    </div>
  </div>
)

export default Loader;
