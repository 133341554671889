import { useEffect } from 'react';
import { useNavigate } from '@shopify/app-bridge-react';

const Redirect = ({ to = '' }) => {
  const navigate = useNavigate();

  useEffect(() => navigate(to), [navigate, to])

  return <></>
}

export default Redirect;
