import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from '@shopify/app-bridge-react';
import { useParams } from 'react-router-dom';
import {
  Page,
  Card,
  EmptyState,
  ButtonGroup,
  Popover,
  Button,
  ActionList,
  Tabs,
  Pagination
} from '@shopify/polaris';
import ActivationsTable from 'components/ActivationsTable';
import LiveProductsTable from 'components/LiveProductsTable';
import Loader from 'components/Loader';
import imageEmptyState from 'assets/images/empty-state.png';
import serviceRetailers from 'services/retailers';
import serviceActivations from 'services/activations';
import serviceProducts from 'services/products';
import utilConstants from 'utils/constants';
import s from './index.module.scss';

const MyRetailerDetails = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [retailerName, setRetailerName] = useState('');
  const [activations, setActivations] = useState([]);
  const [liveProducts, setLiveProducts] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [total, setTotal] = useState(0);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [filterIndex, setFilterIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const navigate = useNavigate();

  const { retailerId } = useParams();

  const getPage = async direction => {
    const directions = {
      'next': serviceActivations.getNext,
      'prev': serviceActivations.getPrev
    };
    setIsLoading(true);
    await directions[direction]().then(data => {
      setActivations(data.activations);
      setHasNext(data.hasNext);
      setHasPrev(data.hasPrev);
      setTotal(data.total);
    })
    setIsLoading(false);
  }

  const getNextPage = useCallback(
    () => getPage('next'),
    []
  )

  const getPrevPage = () => getPage('prev')

  const getLiveProducts = useCallback(
    async () => {
      setLiveProducts([]);
      serviceProducts.live(retailerId).then(data => {
        setLiveProducts(
          data.products.map(
            product => product.variants.filter(
              variant => variant.isLive
            ).map(
              variant => ({
                imageUrl: variant.imageUrl,
                title: product.title,
                variantTitle: product.variants.length > 1 ? variant.labels.join(' ') : '',
                stock: variant.quantity,
                price: variant.price
              })
            )
          ).flat()
        );
      })
    },
    [retailerId]
  )

  useEffect(() => {
    const fetchData = async () => {
      setIsFirstLoading(true);
      await serviceRetailers.my();
      setRetailerName(serviceRetailers.getName(retailerId));
      serviceActivations.discardPagination();
      serviceActivations.setRetailerId(retailerId);
      serviceActivations.setFilter(utilConstants.activationsFilterOptions[0].value);
      await getNextPage();
      await getLiveProducts();
      setIsFirstLoading(false);
    }
    fetchData();
  }, [retailerId, getNextPage, getLiveProducts])

  const handleBack = () => navigate('/retailers/my')

  const handleNewActivation = () => navigate(`/retailers/my/${retailerId}/activations/new`)

  const toggleIsOpenPopover = useCallback(
    () => setIsOpenPopover(isOpenPopover => !isOpenPopover),
    []
  )

  const handleChangeFilter = async index => {
    toggleIsOpenPopover();
    if (index === filterIndex) {
      return;
    }
    setFilterIndex(index);
    serviceActivations.setFilter(utilConstants.activationsFilterOptions[index].value);
    getNextPage();
  }

  const handleChangeTab = index => {
    if (isOpenPopover) {
      toggleIsOpenPopover();
    }
    setSelectedTab(index);
  }

  const handleDeleteActivation = async id => {
    await serviceActivations.delete(id);
    getNextPage();
    getLiveProducts();
  }

  const activeActivations = activations.filter(activation => activation.status !== 'completed');
  const completedActivations = activations.filter(activation => activation.status === 'completed');

  if (isFirstLoading) {
    return <Loader />
  }

  if (!total) {
    return (
      <Page
        title={retailerName}
        primaryAction={{ content: 'Done', onAction: handleBack }}
        breadcrumbs={[{ content: 'My retailers', onAction: handleBack }]}
      >
        <div className={s['my-retailer-details__empty-state-wrapper']}>
          <Card sectioned>
            <EmptyState
              heading='There are no any activations for this retailer'
              action={{ content: 'New activation', onAction: handleNewActivation }}
              image={imageEmptyState}
            >
              <p>Get started by adding a new activation to this retailer.</p>
            </EmptyState>
          </Card>
        </div>
      </Page>
    )
  }

  return (
    <Page
      title={retailerName}
      primaryAction={(
        <ButtonGroup>
          {selectedTab === 0 && (
            <Popover
              active={isOpenPopover}
              activator={(
                <Button
                  onClick={toggleIsOpenPopover}
                  disclosure
                  plain
                >
                  {utilConstants.activationsFilterOptions[filterIndex].label}
                </Button>
              )}
              onClose={toggleIsOpenPopover}
            >
              <ActionList
                items={
                  utilConstants.activationsFilterOptions.map(
                    (activationsFilterOption, index) => (
                      {
                        content: activationsFilterOption.label,
                        onAction: () => handleChangeFilter(index)
                      }
                    )
                  )
                }
              />
            </Popover>
          )}
          <Button primary onClick={handleNewActivation}>New activation</Button>
        </ButtonGroup>
      )}
      breadcrumbs={
        [
          {
            content: 'My retailers',
            onAction: handleBack
          }
        ]
      }
    >
      {isLoading && <Loader />}
      <div className={s['my-retailer-details__tabs']}>
        <Tabs
          tabs={
            [
              { content: 'Activations', id: 'Activations' },
              { content: 'Live products', id: 'Live products' }
            ]
          }
          fitted
          selected={selectedTab}
          onSelect={index => handleChangeTab(index)}
        />
      </div>
      {selectedTab === 0 && (
        <>
          {
            activations.length
              ? (
                <>
                  {!!activeActivations.length && !isLoading && (
                    <ActivationsTable
                      activations={activeActivations}
                      onDelete={handleDeleteActivation}
                    />
                  )}
                  {!!completedActivations.length && !isLoading && (
                    <ActivationsTable
                      title='History'
                      activations={completedActivations}
                      onDelete={handleDeleteActivation}
                    />
                  )}
                  <div className={s['my-retailer-details__pagination-wrapper']}>
                    <Pagination
                      hasNext={!isLoading && hasNext}
                      hasPrevious={!isLoading && hasPrev}
                      onNext={getNextPage}
                      onPrevious={getPrevPage}
                    />
                  </div>
                </>
              )
              : (
                <Card sectioned>
                  <EmptyState image={imageEmptyState}>
                    <p>You have no{utilConstants.indexOfCompletedActivationsFilterOptions !== filterIndex ? 't': ''} {utilConstants.activationsFilterOptions[filterIndex].label.toLowerCase()} activations at this time.</p>
                  </EmptyState>
                </Card>
              )
          }
        </>
      )}
      {selectedTab === 1 && (
        <LiveProductsTable liveProducts={liveProducts} />
      )}
    </Page>
  )
}

export default MyRetailerDetails;
