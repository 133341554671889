import { DisplayText, Link } from '@shopify/polaris';
import iconLogo from 'assets/icons/logo.svg';
import s from './index.module.scss';

const Footer = () => (
  <footer className={s['footer']}>
    <div className={s['footer__wrapper']}>
      <img
        src={iconLogo}
        alt='DISTLL logo'
      />
      <div className={s['footer__column']}>
        <DisplayText size='small'>SERVICES</DisplayText>
        <Link url='https://distll.com/brands-and-creators' external>DISTLL for Brands</Link>
        <Link url='https://www.distll.com/retailers' external>DISTLL for Retailers</Link>
        <Link url='https://www.distll.com/support' external>Support</Link>
      </div>
      <div className={s['footer__column']}>
        <DisplayText size='small'>COMPANY</DisplayText>
        <Link url='https://www.distll.com/about' external>About</Link>
        <Link url='https://www.distll.com/contact' external>Contact</Link>
        <Link url='https://www.distll.com/app-documentation' external>App documentation</Link>
      </div>
      <div className={s['footer__column']}>
        <DisplayText size='small'>LEGAL</DisplayText>
        <Link url='https://www.distll.com/terms/terms-of-service' external>Terms of Service</Link>
        <Link url='https://www.distll.com/terms/privacy-policy' external>Privacy Policy</Link>
        <p>© {new Date().getFullYear()} DISTLL. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
)

export default Footer;
