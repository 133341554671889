import { useState } from 'react';
import { useNavigate } from '@shopify/app-bridge-react';
import { Page, Card, DisplayText, Button } from '@shopify/polaris';
import LogoUploader from 'components/LogoUploader';
import serviceUser from 'services/user';
import s from './index.module.scss';

const Onboarding = ({ onDone = () => {} }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleClickContinue = () => {
    setIsLoading(true);
    serviceUser.update({ onboardStep: 3 }).then(
      () => {
        onDone();
        navigate('/account-settings/startup-guide');
      }
    ).catch(
      () => setIsLoading(false)
    )
  }

  return (
    <Page>
      <div className={s['onboarding']}>
        <Card>
          <div className={s['onboarding__logo-wrapper']}>
            <LogoUploader
              disabled={isLoading}
              onLoadStart={() => setIsUploading(true)}
              onLoadEnd={() => setIsUploading(false)}
            />
          </div>
          <div className={s['onboarding__title-wrapper']}>
            <DisplayText size='small'>Get started</DisplayText>
          </div>
          <p className={s['onboarding__text']}>Upload your brand’s logo.</p>
          <div className={s['onboarding__button-wrapper']}>
            <Button
              disabled={isUploading}
              loading={isLoading}
              primary
              onClick={handleClickContinue}
            >
              Continue
            </Button>
          </div>
        </Card>
      </div>
    </Page>
  )
}

export default Onboarding;
