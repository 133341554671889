import { Route } from 'react-router-dom';
import Redirect from 'components/Redirect';
import serviceShopify from 'services/shopify';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    render={() => serviceShopify.getIsConnected() ? <Redirect to='/dashboard' /> : <Component />}
    {...rest}
  />
)

export default PublicRoute;
