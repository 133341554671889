import React from 'react';
import ReactDOM from 'react-dom/client';
import AppProxy from './AppProxy';
import './index.scss';

const root = ReactDOM.createRoot(document.querySelector('#app-root'));
root.render(
  <React.StrictMode>
    <AppProxy />
  </React.StrictMode>
);
