import serviceApi from 'services/api';
import modelRetailers from 'models/retailers';

const my = () => serviceApi.get('content/v1/contents/syndicatePartnerSchedule').then(response => {
  modelRetailers.my = [...response.active, ...response.inactive];
  return response;
})

const removeMy = id => serviceApi.delete(`brand/v1/brands/syndicatepartners/${id}`)

const all = () => serviceApi.get('brand/v1/syndicatepartners').then(data => {
  modelRetailers.all = data.results;
  return data;
})

const myFromAll = () => serviceApi.get('brand/v1/brands/syndicatepartners')

const addToMy = id => serviceApi.put(`brand/v1/brands/syndicatepartners/${id}`)

const getName = id => {
  const filteredRetailer = modelRetailers.my.filter(retailer => retailer.syndicatePartnerType === id);
  if (!filteredRetailer.length) {
    return '';
  }
  return filteredRetailer[0].displayName;
}

const service = {
  my,
  removeMy,
  all,
  myFromAll,
  addToMy,
  getName
};

export default service;
