import { useState } from 'react';
import { DropZone, Spinner } from '@shopify/polaris';
import Thumbnail from 'components/Thumbnail';
import serviceUser from 'services/user';
import s from './index.module.scss';

const LogoUploader = ({
  disabled = false,
  showPreview = true,
  onLoadStart = () => {},
  onLoadEnd = () => {}
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(undefined);

  useState(() => {
    setIsLoading(true);
    onLoadStart();
    serviceUser.get().then(data => {
      setLogoUrl(data.logoUrl);
      onLoadEnd(data.logoUrl);
    }).finally(() => setIsLoading(false))
  }, [])

  const handleDrop = async (_, acceptedFiles) => {
    if (disabled || isLoading) {
      return;
    }
    if (acceptedFiles.length === 0) {
      return;
    }
    const file = acceptedFiles[0];
    if (file.type.split('/')[0] !== 'image') {
      return;
    }
    setIsLoading(true);
    onLoadStart();
    await serviceUser.uploadLogo(file);
    await serviceUser.get().then(data => {
      setLogoUrl(data.logoUrl);
      onLoadEnd(data.logoUrl);
    })
    setIsLoading(false);
  }

  return (
    <DropZone
      allowMultiple={false}
      type='image'
      onDrop={handleDrop}
    >
      {
        isLoading
          ? (
            <div className={s['logo-uploader__spinner']}>
              <div>
                <Spinner />
              </div>
            </div>
          )
          : logoUrl && showPreview
            ? (
              <div className={s['logo-uploader__thumbnail-wrapper']}>
                <Thumbnail source={logoUrl} alt={logoUrl} />
              </div>
            )
            : <DropZone.FileUpload />
      }
    </DropZone>
  )
}

export default LogoUploader;
