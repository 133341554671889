const getQueryParam = key => new URLSearchParams(window.location.search).get(key)

const extractShopName = () => window.location.ancestorOrigins.length === 1
  ? window.location.ancestorOrigins[0].endsWith('.myshopify.com') && /.*\..*\..*/.test(window.location.ancestorOrigins[0])
    ? window.location.ancestorOrigins[0].split('.')[0].slice(8)
    : ''
  : ''

const util = {
  getQueryParam,
  extractShopName
};

export default util;
