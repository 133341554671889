import serviceApi from 'services/api';
import serviceTokens from 'services/tokens';
import serviceAuth from 'services/auth';
import modelUser from 'models/user';

const baseUrl = 'brand/v1/brands';

const get = () => serviceApi.get(baseUrl)

const isEmailVerefied = () => serviceApi.get(`${baseUrl}/isEmailVerefied`)

const update = data => serviceApi.put(baseUrl, data)

const uploadLogo = async file => {
  const uploadFileData = await serviceApi.post(
    'content/v1/contents/uploadContent',
    { mediaType: file.type, filename: file.name }
  );
  await serviceApi.upload(uploadFileData.url, file);
  await update({ logoUrl: uploadFileData.contentFileId });
}

const disconnectShopify = () => update({ shopifyShopName: '', removeShopify: true })

const connectShopify = auth0token => serviceApi.post(`${baseUrl}/connectShopify`, { auth0token })

const submitEmailVerification = async () => {
  serviceTokens.saveAccess('');
  await serviceAuth.login();
}

const resendVerificationEmail = () => serviceApi.post('brand/v1/brands/verification-email')

const setOnboardStep = onboardStep => modelUser.onboardStep = onboardStep

const getOnboardStep = () => modelUser.onboardStep

const setIsOnboardedShopify = isOnboardedShopify => modelUser.isOnboardedShopify = isOnboardedShopify

const getIsOnboardedShopify = () => modelUser.isOnboardedShopify

const setIsEmailVerefied = isEmailVerefied => modelUser.isEmailVerefied = isEmailVerefied

const getIsEmailVerefied = () => modelUser.isEmailVerefied

const service = {
  get,
  isEmailVerefied,
  update,
  uploadLogo,
  disconnectShopify,
  connectShopify,
  submitEmailVerification,
  resendVerificationEmail,
  setOnboardStep,
  getOnboardStep,
  setIsOnboardedShopify,
  getIsOnboardedShopify,
  setIsEmailVerefied,
  getIsEmailVerefied
};

export default service;
