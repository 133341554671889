import serviceApi from 'services/api';
import modelActivations from 'models/activations';
import utilConstants from 'utils/constants';

const discardPagination = () => {
  modelActivations.prevEvaluatedKeys = ['draft-0'];
  modelActivations.hasNext = true;
  modelActivations.hasPrev = false;
}

const setRetailerId = retailerId => {
  if (retailerId === modelActivations.retailerId) {
    return;
  }
  discardPagination();
  modelActivations.retailerId = retailerId;
}

const setFilter = filter => {
  if (filter === modelActivations.filter) {
    return;
  }
  discardPagination();
  modelActivations.filter = filter;
}

const getLastEvaluatedKeyForNextPage = () => modelActivations.prevEvaluatedKeys[modelActivations.prevEvaluatedKeys.length - 1]

const getNext = () => {
  if (!modelActivations.hasNext) {
    return Promise.reject();
  }
  return serviceApi.get(
    `content/v1/contents/partner/${modelActivations.retailerId}/scheduleSearch`,
    {
      q: modelActivations.q,
      selectedSchedule: modelActivations.filter,
      lastEvaluatedKey: getLastEvaluatedKeyForNextPage(),
      pageLimit: modelActivations.pageSize
    }
  ).then(data => {
    const { meta } = data;
    modelActivations.prevEvaluatedKeys = [...modelActivations.prevEvaluatedKeys, meta.lastEvaluatedKey];
    const getTotal = {
      '': () => meta.totalSize,
      live: () => meta.totalLive,
      draft: () => meta.totalDraft,
      scheduled: () => meta.totalScheduled,
      completed: () => meta.totalCompleted,
    };
    const pageCount = Math.ceil(getTotal[modelActivations.filter]() / meta.pageLimit);
    modelActivations.hasNext = modelActivations.prevEvaluatedKeys.length - 1 < pageCount;
    modelActivations.hasPrev = modelActivations.prevEvaluatedKeys.length > 2;
    if (modelActivations.isDeletePrevRequest && modelActivations.total === meta.totalSize) {
      modelActivations.total -= 1;
    } else {
      modelActivations.total = meta.totalSize;
    }
    modelActivations.isDeletePrevRequest = false;
    return {
      activations: data.schedules,
      hasNext: modelActivations.hasNext,
      hasPrev: modelActivations.hasPrev,
      total: modelActivations.total
    };
  })
}

const getLastEvaluatedKeyForPrevPage = () => modelActivations.prevEvaluatedKeys[modelActivations.prevEvaluatedKeys.length - 3]

const getPrev = () => {
  if (!modelActivations.hasPrev) {
    return Promise.reject();
  }
  return serviceApi.get(
    `content/v1/contents/partner/${modelActivations.retailerId}/scheduleSearch`,
    {
      q: modelActivations.q,
      selectedSchedule: modelActivations.filter,
      lastEvaluatedKey: getLastEvaluatedKeyForPrevPage(),
      pageLimit: modelActivations.pageSize
    }
  ).then(data => {
    modelActivations.prevEvaluatedKeys = modelActivations.prevEvaluatedKeys.slice(0, -1);
    modelActivations.hasNext = true;
    modelActivations.hasPrev = modelActivations.prevEvaluatedKeys.length > 2;
    if (modelActivations.isDeletePrevRequest && modelActivations.total === data.meta.totalSize) {
      modelActivations.total -= 1;
    } else {
      modelActivations.total = data.meta.totalSize;
    }
    modelActivations.isDeletePrevRequest = false;
    return {
      activations: data.schedules,
      hasNext: modelActivations.hasNext,
      hasPrev: modelActivations.hasPrev,
      total: modelActivations.total
    };
  })
}

const _delete = id => serviceApi.post(`content/v1/contents/deleteContentSchedule/${id}`).then(discardPagination).finally(() => {
  modelActivations.isDeletePrevRequest = true;
})

const create = (retailerId, title, dateRange, startTimeIndex, endTimeIndex, selectedProducts) => {
  const formatDate = (date, timeIndex) => {
    const dateTime = new Date(date);
    dateTime.setHours(...utilConstants.activationTimes[timeIndex].value.split(':'));
    return dateTime.toISOString();
  }

  return serviceApi.post(
    `content/v1/contents/schedule/partner/${retailerId}`,
    {
      description: '',
      isDrafted: false,
      mediaUrl: [''],
      productOrContent: 'product',
      type: 'image',
      title,
      startDate: formatDate(dateRange.start, startTimeIndex),
      endDate: formatDate(dateRange.end, endTimeIndex),
      productAndVariantIds: selectedProducts
    }
  ).then(() => {
    modelActivations.isDeletePrevRequest = false;
  }).catch(
    error => Promise.reject(error.response.data.error.description)
  )
}

const service = {
  discardPagination,
  setRetailerId,
  setFilter,
  getNext,
  getPrev,
  delete: _delete,
  create
};

export default service;
