import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from '@shopify/app-bridge-react';
import Router from 'components/Router';
import App from 'App';
import utilConstants from 'utils/constants';
import utilUrl from 'utils/url';
import utilGenerators from 'utils/generators';
import serviceShopify from 'services/shopify';

const AppProxy = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (window.top != window.self) {
      setIsLoaded(true)
      return
    }
    if (utilUrl.getQueryParam('code')) {
      const proceed = async () => {
        await serviceShopify.installComplete()
        window.location.assign(utilGenerators.appUrl())
      }
      proceed()
      return
    }
    const proceed = async () => {
      await serviceShopify.installRequest()
      window.location.assign(utilGenerators.permissionUrl())
    }
    proceed()
  }, [])

  if (!isLoaded) {
    return <></>
  }

  return (
    <BrowserRouter>
      <Provider config={utilConstants.shopifyAppBridgeConfg}>
        <Router />
        <App />
      </Provider>
    </BrowserRouter>
  )
}

export default AppProxy;
