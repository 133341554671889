import { Thumbnail as PolarisThumbnail } from '@shopify/polaris';

const Thumbnail = ({ source, ...rest }) => (
  <PolarisThumbnail
    source={source || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='}
    {...rest}
  />
)

export default Thumbnail;
