import { useState, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { useRoutePropagation, useAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { AppProvider } from '@shopify/polaris';
import Loader from 'components/Loader';
import Redirect from 'components/Redirect';
import PublicRoute from 'components/routes/PublicRoute';
import PrivateRoute from 'components/routes/PrivateRoute';
import GetStarted from 'pages/account-settings/get-started';
import MyRetailers from 'pages/retailers/my';
import Retailers from 'pages/retailers';
import MyRetailerDetails from 'pages/retailers/my/details';
import NewActivation from 'pages/retailers/my/details/activations/new';
import StartupGuide from 'pages/account-settings/startup-guide';
import Onboarding from 'pages/account-settings/onboarding';
import AccountSettings from 'pages/account-settings';
import VerifyEmail from 'pages/account-settings/verify-email';
import enTranslations from '@shopify/polaris/locales/en.json';
import serviceTokens from 'services/tokens';
import serviceUser from 'services/user';
import serviceShopify from 'services/shopify';
import '@shopify/polaris/build/esm/styles.css';

const App = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true);

  useRoutePropagation(location);

  const app = useAppBridge();

  const processSessionToken = async () => {
    const sessionToken = await getSessionToken(app);
    serviceTokens.saveAccess(sessionToken);
  }

  useEffect(() => {
    const fetch = async () => {
      await processSessionToken();
      try {
        const user = await serviceUser.get();
        serviceUser.setOnboardStep(user.onboardStep);
        serviceUser.setIsOnboardedShopify(user.isOnboardedShopify);
        serviceShopify.saveIsConnected(true);
        const { email_verified: emailVerified } = await serviceUser.isEmailVerefied();
        serviceUser.setIsEmailVerefied(emailVerified);
      } catch {

      }
      const { exp, iat } = serviceTokens.getTokenPayload();
      const tokenExpMs = (exp - iat) * 1000;
      setInterval(processSessionToken, tokenExpMs);
      setIsLoading(false);
    }
    fetch();
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <AppProvider i18n={enTranslations}>
      <Switch>
        <Route exact path='/login' render={() => <Redirect to='/account-settings/get-started' />} />
        <Route exact path='/dashboard' render={() => <Redirect to='/retailers/my' />} />
        <PublicRoute exact path='/account-settings/get-started' component={GetStarted} />
        <PrivateRoute exact path='/retailers/my' component={MyRetailers} />
        <PrivateRoute exact path='/retailers' component={Retailers} />
        <PrivateRoute exact path='/retailers/my/:retailerId' component={MyRetailerDetails} />
        <PrivateRoute exact path='/retailers/my/:retailerId/activations/new' component={NewActivation} />
        <PrivateRoute exact path='/account-settings/startup-guide' component={StartupGuide} />
        <PrivateRoute exact path='/account-settings/onboarding' component={Onboarding} />
        <PrivateRoute exact path='/account-settings' component={AccountSettings} />
        <PrivateRoute exact path='/account-settings/verify-email' component={VerifyEmail} />
        <Route path='*' render={() => <Redirect to='/account-settings/get-started' />} />
      </Switch>
    </AppProvider>
  )
}

export default withRouter(App);
