import modelTokens from 'models/tokens';

const saveAccess = access => modelTokens.access = access

const getAccess = () => modelTokens.access

const getTokenPayload = () => {
  try {
    return JSON.parse(atob((getAccess()).split('.')[1]));
  } catch {
    return {
      exp: 0,
      iat: 0,
      email_verified: false
    };
  }
}

const service = {
  saveAccess,
  getAccess,
  getTokenPayload
};

export default service;
