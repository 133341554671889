import { useState } from 'react';
import { useNavigate } from '@shopify/app-bridge-react';
import { Page, Card, EmptyState } from '@shopify/polaris';
import imageDeckChair from 'assets/images/deck-chair.png';
import serviceUser from 'services/user';
import s from './index.module.scss';

const VerifyEmail = ({ onDone = () => {} }) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const isLoading = isSubmiting || isResending;

  const navigate = useNavigate();

  const handleClickContinue = () => {
    if (isLoading) {
      return;
    }
    setIsSubmiting(true);
    serviceUser.isEmailVerefied().then(({ email_verified }) => {
      if (!email_verified) {
        setIsSubmiting(false);
        return;
      }
      onDone();
      navigate('/account-settings/get-started');
    }).catch(
      () => setIsSubmiting(false)
    )
  }

  const handleClickResend = () => {
    if (isLoading) {
      return;
    }
    setIsResending(true);
    serviceUser.resendVerificationEmail().finally(
      () => setIsResending(false)
    );
  }

  return (
    <Page>
      <div className={s['verify-email__empty-state-wrapper']}>
        <Card sectioned>
          <EmptyState
            heading='Welcome!'
            action={{
              content: 'Continue',
              onAction: handleClickContinue,
              disabled: isLoading,
              loading: isSubmiting
            }}
            secondaryAction={{
              content: 'Resend verification email',
              onAction: handleClickResend,
              disabled: isLoading,
              loading: isResending
            }}
            image={imageDeckChair}
          >
            <p>
              Thank you for signing up.<br />
              Please check your inbox for a verification email.
            </p>
          </EmptyState>
        </Card>
      </div>
    </Page>
  )
}

export default VerifyEmail;
