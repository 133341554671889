import { useState } from 'react';
import { useNavigate } from '@shopify/app-bridge-react';
import { Link, Modal } from '@shopify/polaris';
import Loader from 'components/Loader';
import imageSneakersMobile from 'assets/images/sneakers-mobile.png';
import imageSneakersDesktop from 'assets/images/sneakers-desktop.png';
import serviceAuth from 'services/auth';
import serviceUser from 'services/user';
import serviceShopify from 'services/shopify';
import utilUrl from 'utils/url';
import s from './index.module.scss'

const GetStarted = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveAccountConflictModal, setIsActiveAccountConflictModal] = useState(serviceAuth.getIsAccountConflict());

  const navigate = useNavigate();

  const handleOpenAccountConflictModal = () => {
    setIsActiveAccountConflictModal(true);
    serviceAuth.setIsAccountConflict(true);
  }

  const handleCloseAccountConflictModal = () => {
    setIsActiveAccountConflictModal(false);
    serviceAuth.setIsAccountConflict(false);
  }

  const handleClickConnect = async () => {
    setIsLoading(true);
    handleCloseAccountConflictModal();
    const shopifyShopName = utilUrl.extractShopName();
    if (!shopifyShopName) {
      setIsLoading(false);
      return;
    }
    const token = await serviceAuth.login();
    try {
      await serviceUser.connectShopify(token);
      const user = await serviceUser.get();
      serviceUser.setOnboardStep(user.onboardStep);
      serviceUser.setIsOnboardedShopify(user.isOnboardedShopify);
    } catch {
      handleOpenAccountConflictModal();
      serviceAuth.logout();
      setIsLoading(false);
      return;
    }
    serviceShopify.saveIsConnected(true);
    const { email_verified: emailVerified } = await serviceUser.isEmailVerefied();
    serviceUser.setIsEmailVerefied(emailVerified);
    setIsLoading(false);
    navigate('/dashboard');
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={s['get-started']}>
      <div className={s['get-started__content']}>
        <h1 className={s['get-started__title']}>Amplify Your Story.</h1>
        <h1 className={s['get-started__title']}>Amplify Your Revenue.</h1>
        <div className={s['get-started__line']}></div>
        <h2 className={s['get-started__subtitle']}>Your Next Generation Commerce Solution.</h2>
        <div className={s['get-started__button']} onClick={handleClickConnect}>Connect</div>
        <div className={s['get-started__text']}>
          By clicking CONNECT, you agree to accept DISTLL's <Link url='https://www.distll.com/terms/terms-of-service' external>terms and conditions</Link> and <Link url='https://www.distll.com/terms/privacy-policy' external>privacy policy</Link><br />
          You'll pay a commission of 10% on sales made through DISTLL. 
        </div>
      </div>
      <img
        src={imageSneakersMobile}
        className={`${s['get-started__image']} ${s['get-started__image--mobile']}`}
        alt='Sneakers'
      />
      <img
        src={imageSneakersDesktop}
        className={`${s['get-started__image']} ${s['get-started__image--desktop']}`}
        alt='Sneakers'
      />
      <Modal
        title=''
        titleHidden
        open={isActiveAccountConflictModal}
        secondaryActions={[
          {
            content: 'Close',
            onAction: handleCloseAccountConflictModal,
          }
        ]}
        onClose={handleCloseAccountConflictModal}
      >
        <p className={s['get-started__modal-text']}>This brand is connected to another Shopify store</p>
      </Modal>
    </div>
  )
}

export default GetStarted;
