import { useState, useEffect } from 'react';
import { TextField, Card, DatePicker } from '@shopify/polaris';
import utilFormatters from 'utils/formatters';
import s from './index.module.scss';

const DateRangePicker = ({
  error = '',
  onChange = () => {}
}) => {
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDates, setSelectedDates] = useState(
    {
      start: new Date(),
      end: new Date()
    }
  );

  useEffect(() => {
    const handleClick = ({ target }) => {
      if (!isOpenDatePicker) {
        return;
      }
      let isDateRangePickerClick = false;
      while (target) {
        if (target.classList.contains(s['date-range-picker__date-picker']) || target.classList.contains('Polaris-TextField__Input')) {
          isDateRangePickerClick = true;
          break;
        }
        target = target.parentElement;
      }
      if (isDateRangePickerClick) {
        return;
      }
      setIsOpenDatePicker(false);
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick)
  }, [isOpenDatePicker])

  const handleOpenDatePicker = () => setIsOpenDatePicker(true)

  const handleChange = newRange => {
    setSelectedDates(newRange);
    onChange(newRange);
  }

  const handleMonthChange = (month, year) => {
    setMonth(month);
    setYear(year);
  }

  const disabledDatesBefore = new Date();
  disabledDatesBefore.setDate(disabledDatesBefore.getDate() - 1);

  const textFieldValue = `${utilFormatters.formatTextDate(selectedDates.start)} - ${utilFormatters.formatTextDate(selectedDates.end)}`;

  return (
    <div className={s['date-range-picker']} onClick={handleOpenDatePicker}>
      <TextField
        value={textFieldValue}
        label='Select time period'
        autoComplete='off'
        error={error}
      />
      {isOpenDatePicker && (
        <div className={s['date-range-picker__date-picker']}>
          <Card>
            <DatePicker
              month={month}
              year={year}
              disableDatesBefore={disabledDatesBefore}
              selected={selectedDates}
              onChange={handleChange}
              onMonthChange={handleMonthChange}
              allowRange
              multiMonth
            />
          </Card>
        </div>
      )}
    </div>
  )
}

export default DateRangePicker;
