import serviceApi from 'services/api';
import modelShopify from 'models/shopify';

const baseUrl = 'payment/v1/shopify';

const installRequest = () => serviceApi.get(`${baseUrl}/redirect${window.location.search}`)

const installComplete = () => serviceApi.get(`${baseUrl}/code${window.location.search}`)

const saveIsConnected = isConnected => modelShopify.isConnected = isConnected

const getIsConnected = () => modelShopify.isConnected

const service = {
  installRequest,
  installComplete,
  saveIsConnected,
  getIsConnected
};

export default service;
