import utilUrl from 'utils/url';
import utilConstants from 'utils/constants';

const permissionUrl = () => `https://${utilUrl.getQueryParam('shop')}/admin/oauth/authorize?client_id=${utilConstants.shopifyAppApiKey}&scope=${utilConstants.shopifyAppScope}&redirect_uri=${utilConstants.shopifyAppRedirectUri}`
const appUrl = () => `https://${utilUrl.getQueryParam('shop')}/admin/apps/${utilConstants.shopifyAppId}/`

const util = {
  permissionUrl,
  appUrl
};

export default util;
