import serviceApi from 'services/api';
import utilUrl from 'utils/url';

const isShowProductsBannerLocalStorageKey = `isShowProductsBanner:${utilUrl.extractShopName()}`;

const live = retailerId => serviceApi.get(`content/v1/contents/partner/${retailerId}/liveProductSearch`, { pageLimit: 10000 })

const getPage = (page = 0) => {
  const pageLimit = 10;
  const pagination = page === 0 ? {} : { lastEvaluatedKey: `${page}0` };

  return serviceApi.get('product/v1/product/search', { pageLimit, ...pagination }).then(data => {
    const pageCount = (
      parseInt(data.total / pageLimit)
      + (data.total % pageLimit === 0 ? 0 : 1)
    );
    return {
      products: data.results,
      hasNext: page < pageCount - 1,
      hasPrev: page !== 0
    };
  })
}

const getAllMyProductsExternalIds = () => serviceApi.get('product/v1/product/getAllMyProductsExternalIds')

const setIsShowProductsBanner = isShowProductsBanner => {
  try {
    localStorage.setItem(isShowProductsBannerLocalStorageKey, isShowProductsBanner);
  } catch {

  }
}

const getIsShowProductsBanner = () => {
  try {
    const result = JSON.parse(localStorage.getItem(isShowProductsBannerLocalStorageKey));
    if (typeof result === 'boolean') {
      return result;
    }
    return true;
  } catch {
    return true;
  }
}

const service = {
  live,
  getPage,
  getAllMyProductsExternalIds,
  setIsShowProductsBanner,
  getIsShowProductsBanner
};

export default service;
